import React from "react";
import "./Button.css";

const Button = ({
  text,
  onClick = () => {},
  color,
  backgroundColor,
  size,
  disabled = false,
  imgSrc = null,
  imgAlt = "",
  type = "button",
  className = "",
}) => {
  return (
    <button
      className={`button ${size} ${className}`}
      style={{ color, backgroundColor }}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {imgSrc && <img src={imgSrc} alt={imgAlt} className="button-image" />}
      {text}
    </button>
  );
};

export default Button;
