import React from "react";
import Bell from "../../assets/svgs/Bell";
import CalenderSvg from "../../assets/svgs/CalenderSvg";
import ExitSvg from "../../assets/svgs/ExitSvg";
import GroupSvg from "../../assets/svgs/GroupSvg";
import GridSvg from "../../assets/svgs/GridSvg";
import SingleLineGraphSvg from "../../assets/svgs/SingleLineGraphSvg";
import UserSvg from "../../assets/svgs/UserSvg";
import DepartmentLineSvg from "../../assets/svgs/DepartmentLineSvg";
import ReportBoxSvg from "../../assets/svgs/ReportBoxSvg";
import GuardSvg from "../../assets/svgs/GuardSvg";
import SettingSvg from "../../assets/svgs/SettingSvg";
import "./Sidebar.css";

const Sidebar = () => {
  return (
    <div className="sidebar-container">
      <div className="sidebar-section">
        <Bell width={25} height={25} stroke={"#252C58"} />
        <GroupSvg width={25} height={25} stroke={"#252C58"} />
      </div>
      <div className="sidebar-section ">
        <GridSvg width={25} height={25} fillColor={" #A3AED0"} />
        <SingleLineGraphSvg width={25} height={25} fillColor={" #A3AED0"} />
        <UserSvg width={25} height={25} fillColor={" #252C58"} />
        <DepartmentLineSvg width={25} height={25} fillColor={" #A3AED0"} />
        <ReportBoxSvg width={25} height={25} fillColor={" #A3AED0"} />
        <CalenderSvg width={25} height={25} fillColor={" #A3AED0"} />
        <span className="border-bottom"></span>
      </div>
      <div className="sidebar-section">
        <SettingSvg width={25} height={25} fillColor={" #A3AED0"} />
        <GuardSvg width={25} height={25} fillColor={" #A3AED0"} />
      </div>
      <div className="sidebar-section">
        <span className="border-bottom"></span>
        <ExitSvg width={25} height={25} fillColor={" #A3AED0"} />
      </div>
    </div>
  );
};

export default Sidebar;
