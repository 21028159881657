import React from "react";

const GroupSvg = ({ fillColor, width, height, stroke }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill={fillColor || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.375 1V19M19 3.25C19 2.65326 18.7629 2.08097 18.341 1.65901C17.919 1.23705 17.3467 1 16.75 1H3.25C2.65326 1 2.08097 1.23705 1.65901 1.65901C1.23705 2.08097 1 2.65326 1 3.25V16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H16.75C17.3467 19 17.919 18.7629 18.341 18.341C18.7629 17.919 19 17.3467 19 16.75V3.25Z"
        stroke={stroke || "#252C58"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.625 7.75L8.875 10L6.625 12.25"
        stroke={stroke || "#252C58"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GroupSvg;
