import React, { useEffect, useState } from "react";
import Weathercard from "../Weather/Weathercard";
import Cards from "../Cards/Cards";
import "./DashboardComponents.css";
import { OperationalCardData } from "../../utils/data";
import SalesOverview from "../Sales/SalesOverview";
import apiClient from "../../utils/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { userSalesInfo } from "../../Redux/userSlice";
import Button from "../Button/Button";
import Table from "../Table/Table";

const Operational = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const reduxAllSalesInfo = useSelector((state) => state?.user?.salesInfo);

  const userRole = useSelector((state) => state?.user?.role);

  const getData = async () => {
    try {
      const response = await apiClient.get("/api/dashboard");
      if (response?.data) {
        dispatch(userSalesInfo(response?.data?.sales));
      }
    } catch (error) {
      console.log("Error fetching sales data from sales table: ", error);
    }
  };

  useEffect(() => {
    if (reduxAllSalesInfo.length === 0) {
      getData();
    }
  }, []);

  // const todaysDate = new Date().toDateString();
  // const todaySalesArray = allSalesInfo?.filter((data) =>
  //   new Date(data.date_and_time).toDateString().includes(todaysDate)
  // );

  return (
    <>
      <div className="top-Cards">
        {/* weather sections */}
        {/* <Weathercard />
        <div className="cards">
          {OperationalCardData?.map((card, index) => (
            <Cards
              card={card}
              index={index}
              key={index}
              varient={"SuperAdmin"}
            />
          ))}
        </div> */}

        {userRole === "operations" ? (
          // <div style={{ width: "100%" }}>
          //   <SalesOverview
          //     data={reduxAllSalesInfo}
          //     currentPage={currentPage}
          //     itemsPerPage={itemsPerPage}
          //   />

          //   <div className="pagination-container">
          //     <div className="pagination">
          //       <Button
          //         onClick={() => handlePageChange(currentPage - 1)}
          //         disabled={currentPage === 1}
          //         text={"Prev"}
          //       />
          //       <span>{currentPage}</span>
          //       <Button
          //         onClick={() => handlePageChange(currentPage + 1)}
          //         disabled={
          //           currentPage * itemsPerPage >= reduxAllSalesInfo.length
          //         }
          //         text={"Next"}
          //       />
          //     </div>
          //   </div>
          // </div>
          <div>
            <Table />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Operational;
