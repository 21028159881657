import React from "react";
import Button from "../Button/Button";
import "./SalesSideNav.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { activeSaleSideNav } from "../../Redux/userSlice";
const SalesSideNav = ({ setAddBtn, addBtn, setActivePage, activePage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(addBtn);
  return (
    <div className="sales-sidenav">
      <h1>Authorization</h1>
      <div className="link-Container-responsive">
        <div className="link-Container">
          <div onClick={() => dispatch(activeSaleSideNav("sales"))}>
            <p
              className={`text-style ${
                activePage === "sales" ? " active" : ""
              }`}
            >
              Enrollements{" "}
            </p>
          </div>
          <p>
            <Button
              disabled={activePage !== "sales"}
              onClick={() => {
                navigate(`/sales-app/sales`);
              }}
              text={`${addBtn.sales === true ? "Back" : "+Add"}`}
            />
          </p>
        </div>

        <div className="link-Container">
          <div onClick={() => dispatch(activeSaleSideNav("users"))}>
            <p
              className={`text-style ${
                activePage === "users" ? " active" : ""
              }`}
            >
              Users{" "}
            </p>
          </div>

          <p>
            <Button
              disabled={activePage !== "users"}
              onClick={() => {
                // setActivePage("users");
                navigate(`/sales-app/user`);
              }}
              text={`${addBtn.user === true ? "Back" : "+Add"}`}
            />
          </p>
        </div>
        <div className="link-Container">
          <div onClick={() => dispatch(activeSaleSideNav("attendance"))}>
            <p
              className={`text-style ${
                activePage === "attendance" ? " active" : ""
              }`}
            >
              Attendance{" "}
            </p>
          </div>
          <p>
            <Button
              disabled={activePage !== "attendance"}
              // disabled={true}
              onClick={() => {
                setAddBtn({ ...addBtn, attendance: !addBtn.attendance });
              }}
              text={`${addBtn.attendance === true ? "Back" : "+Add"}`}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default SalesSideNav;
