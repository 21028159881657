import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  role: Cookies.get("role") || "",
  salesInfo: [],
  allUsersInfo: [],
  activeSaleSideNav: "sales",
  welcomeMessage: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userState: (state, action) => {
      state.role = action.payload;
    },
    userSalesInfo: (state, action) => {
      state.salesInfo = action.payload;
    },
    allUsersInfo: (state, action) => {
      state.allUsersInfo = action.payload;
    },
    activeSaleSideNav: (state, action) => {
      state.activeSaleSideNav = action.payload;
    },
    welcomeMessage: (state, action) => {
      state.welcomeMessage = action.payload;
    },
  },
});

export const {
  userState,
  userSalesInfo,
  allUsersInfo,
  activeSaleSideNav,
  welcomeMessage,
} = userSlice.actions;

export default userSlice.reducer;
