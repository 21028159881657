import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiClient from "../../utils/axiosConfig";
import { allUsersInfo, userSalesInfo } from "../../Redux/userSlice";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "./SalesPerson.css"; // Import CSS file for component-specific styles
import ClipLoader from "react-spinners/ClipLoader";

const SalesPerson = () => {
  const dispatch = useDispatch();
  const salesPerson = useSelector((state) => state.user.allUsersInfo);
  const salesInfo = useSelector((state) => state.user.salesInfo);
  const [loading, setLoading] = useState(false);

  // Fetch user data from API
  const fetchUserData = async () => {
    try {
      const response = await apiClient.get("/api/user/users");
      dispatch(allUsersInfo(response.data.users));
    } catch (e) {
      console.error(e);
    }
  };

  // Fetch sales data from API
  const fetchSalesData = async () => {
    try {
      const response = await apiClient.get("/api/dashboard");
      dispatch(userSalesInfo(response.data.sales));
    } catch (e) {
      console.error(e);
    }
  };

  // UseEffect to fetch data when component mounts
  useEffect(() => {
    if (salesPerson.length === 0 || salesInfo.length === 0) {
      setLoading(true);
      fetchUserData();
      fetchSalesData();
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [salesPerson.length, salesInfo.length]);

  // Get unique registration codes for salespersons
  // const uniqueSalesPersonRegCodes = Array.from(
  //   new Set(salesPerson.filter((user) => user.role === "salesperson"))
  // );
  // const uniqueSalesInfoRegCodes = Array.from(
  //   new Set(salesInfo.map((sale) => sale.registration_code))
  // );

  // console.log(
  //   "Unique SalesPerson Registration Code:",
  //   uniqueSalesPersonRegCodes
  // );
  // console.log("Unique SalesInfo Registration Codes:", uniqueSalesInfoRegCodes);

  // Filter salespersons to get unique ones by registration code
  const filterSalesPerson = salesPerson
    .filter((user) => user.role === "salesperson")
    .reduce((acc, current) => {
      const x = acc.find(
        (item) => item.registration_code === current.registration_code
      );
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);

  // console.log("Filtered SalesPerson:", filterSalesPerson);

  return (
    <>
      <Navbar />

      <div className="salesperson-container">
        <h1 className="salesperson-heading">Sales Info</h1>
        {loading ? (
          <div className="loader-container">
            <ClipLoader
              color={"#4b74ff"}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <table className="salesperson-table">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Registration Code</th>
                <th>View Details</th>
              </tr>
            </thead>
            <tbody>
              {filterSalesPerson.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>{user.registration_code}</td>
                  <td>
                    <Link to={`/salesperson/${user.registration_code}`}>
                      View Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default SalesPerson;
