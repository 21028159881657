import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Button from "../../components/Button/Button";
import apiClient from "../../utils/axiosConfig";
import { userSalesInfo } from "../../Redux/userSlice";
import "./SalesPersonSalesInfo.css"; // Import new CSS file

const SalesPersonSalesInfo = () => {
  const { regID } = useParams();
  const salesInfo = useSelector((state) => state.user.salesInfo);
  const salesPerson = useSelector((state) => state.user.allUsersInfo);
  const dispatch = useDispatch();

  const getUser = salesPerson.find((user) => user.registration_code === regID);

  const fetchSalesData = async () => {
    try {
      const response = await apiClient.get("/api/dashboard");

      if (response.data) {
        const sortedData = response.data.sales.slice().sort((a, b) => {
          const dateA = new Date(a.date_and_time);
          const dateB = new Date(b.date_and_time);
          return dateB - dateA;
        });

        dispatch(userSalesInfo(sortedData));
      }
    } catch (error) {
      console.error(
        "Error fetching sales data:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    if (!salesInfo.length) {
      fetchSalesData();
    }
  }, [salesInfo]);

  const groupByMonth = (sales) => {
    const grouped = sales.reduce((acc, sale) => {
      const date = new Date(sale.date_and_time);
      const year = date.getFullYear();
      const monthLong = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(date);
      const yearMonth = `${year} ${monthLong}`;

      if (!acc[yearMonth]) {
        acc[yearMonth] = [];
      }

      acc[yearMonth].push(sale);
      return acc;
    }, {});

    const sortedKeys = Object.keys(grouped).sort((a, b) => {
      const [aYear, aMonth] = a.split(" ");
      const [bYear, bMonth] = b.split(" ");
      const aDate = new Date(`${aMonth} 1, ${aYear}`);
      const bDate = new Date(`${bMonth} 1, ${bYear}`);
      return bDate - aDate;
    });

    const sortedGrouped = sortedKeys.reduce((acc, key) => {
      acc[key] = grouped[key];
      return acc;
    }, {});

    return sortedGrouped;
  };

  const getSales = salesInfo.filter((sale) => sale.registration_code === regID);
  const sortedSalesData = getSales.sort((a, b) => {
    return new Date(b.date_and_time) - new Date(a.date_and_time);
  });
  // console.log("sortedSalesData", sortedSalesData);

  const salesByMonth = groupByMonth(sortedSalesData);

  // console.log(salesByMonth);

  const groupByDate = (sales) => {
    const grouped = sales.reduce((acc, sale) => {
      const date = new Date(sale.date_and_time).toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(sale);
      return acc;
    }, {});
    return grouped;
  };

  // console.log("raw Sales data", getSales);
  const newDataSortedByDate = groupByDate(getSales);
  // console.log("newDataSortedByDate", newDataSortedByDate);
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const getSalesCount = (date) => {
    const sales = newDataSortedByDate[date];
    return sales ? sales.length : 0;
  };

  const getMonthlySalesCount = (date) => {
    const sales = salesByMonth[date];
    return sales ? sales.length : 0;
  };

  const getTotalSalesAmount = (date) => {
    const sales = newDataSortedByDate[date];
    return sales
      ? sales.reduce((acc, sale) => acc + Number(sale.amount), 0)
      : 0;
  };

  const getMonthlySalesAmount = (date) => {
    // console.log("date", date);
    const sales = salesByMonth[date];
    return sales
      ? sales.reduce((acc, sale) => acc + Number(sale.amount), 0)
      : 0;
  };

  return (
    <>
      <Navbar />
      <div className="button-container">
        <Button text={"Back"} onClick={() => window.history.back()} />
      </div>
      <div className="sales-info-container">
        <div className="user-details">
          <div>
            <p>
              <span>Name:</span>{" "}
              {`${
                getUser
                  ? getUser?.first_name + " " + getUser?.last_name
                  : "No Data Found!"
              }`}
            </p>
            <p>
              <span>Email:</span> {getUser ? getUser?.email : "No Data Found!"}
            </p>
          </div>
          <div>
            <p>
              <span>Role:</span> {getUser ? getUser?.role : "No Data Found!"}
            </p>
            <p>
              <span>Registration Code:</span>
              {getUser ? getUser?.registration_code : "No Data Found!"}
            </p>
          </div>
        </div>

        <div className="monthly-sales-data-wrapper">
          <h2 className="monthly-sales-heading-title">
            Get Monthly Sales Information
          </h2>

          <table className="monthly-sales-info-table">
            <thead>
              <tr className="monthly-sales-table-header-row">
                <th className="monthly-sales-header-cell">Month</th>
                <th className="monthly-sales-header-cell">Sales Count</th>
                <th className="monthly-sales-header-cell">
                  Total Sales Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {Object?.keys(salesByMonth)?.map((month, index) => (
                <tr key={index} className="monthly-sales-data-row">
                  <td className="monthly-sales-data-cell">{month}</td>
                  <td className="monthly-sales-data-cell">
                    {getMonthlySalesCount(month)}
                  </td>
                  <td className="monthly-sales-data-cell">
                    ₹{getMonthlySalesAmount(month)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {!Object.keys(newDataSortedByDate).length ? (
          <p>No sales found</p>
        ) : (
          <div className="sales-data-container">
            <h2 className="monthly-sales-heading-title">
              Get Sales Information by Date
            </h2>
            {Object.keys(newDataSortedByDate).map((date, index) => (
              <div
                className="sales-day-container"
                key={index}
                style={{ overflow: "auto" }}
              >
                <div className="sales-day-header">
                  <p>Sales on {date}</p>
                  <p>Sales Count: {getSalesCount(date)}</p>
                  <p>Total Sales Amount: ₹{getTotalSalesAmount(date)}</p>
                </div>

                <table className="sales-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order ID</th>
                      <th>Amount</th>
                      {/* <th>Date</th> */}
                      <th>Time</th>
                      <th>Phone Number</th>
                      <th>Customer Name</th>
                      <th>Customer Email</th>
                      {/* <th>Payment Method</th> */}
                      <th>Batch</th>
                      <th>Registration Code</th>
                      <th>Internship</th>
                      <th>Approval Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newDataSortedByDate[date].map((sale, idx) => (
                      <tr key={sale.id}>
                        <td>{idx + 1}</td>
                        <td className="order-id">
                          <Link to={`/sales/${sale.id}`}>
                            {sale.order_id.slice(0, 10)}...
                          </Link>
                        </td>
                        <td>{sale.amount}</td>
                        {/* <td>
                          {new Date(sale.date_and_time).toLocaleDateString(
                            "en-GB",
                            { day: "numeric", month: "short", year: "numeric" }
                          )}
                        </td> */}
                        <td>{formatDate(sale.date_and_time)}</td>
                        <td>{sale.customer_phone}</td>
                        <td className="customer-name">{sale.customer_name}</td>
                        <td>{sale.customer_email}</td>
                        {/* <td>{sale.payment_method}</td> */}
                        <td>{sale.batch}</td>
                        <td>{sale.registration_code}</td>
                        <td className="internship">{sale.internship}</td>
                        <td className={`status ${sale.approval_status}`}>
                          {sale.approval_status}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SalesPersonSalesInfo;
