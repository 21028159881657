import "./App.css";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { BrowserRouter, Outlet, Navigate } from "react-router-dom";
import { Route, Routes } from "react-router";
import SalesDetail from "./Pages/salesDetail/SalesDetail";
import Login from "./Pages/Login/Login";
import SalesApp from "./Pages/SalesApp/SalesApp";
import SalesAttendance from "./components/SalesAttendance/SalesAttendance";
import AddSale from "./components/AddSale/AddSale";
import UserProfileForm from "./components/SalesApp/UserMainContent/UserAdd/UserProfileForm";
import { useSelector } from "react-redux";
import SalesPerson from "./components/SalesPerson/SalesPerson";
import SalesPersonSalesInfo from "./Pages/SalesPersonSalesInfo/SalesPersonSalesInfo";

function App() {
  const role = useSelector((state) => state.user.role);
  // console.log(role);

  const PrivateRoutes = () => {
    return role ? <Outlet /> : <Navigate to="/login" />;
  };

  const AdminRoute = () => {
    return role === "superadmin" ? <Outlet /> : <Navigate to="/" />;
  };

  //   // auth.js
  // import Cookies from 'js-cookie';

  // export function isAuthenticated() {
  //   const token = Cookies.get('token');
  //   return !!token; // Return true if the token exists, false otherwise
  // }

  // export function clearAuthData() {
  //   Cookies.remove('token');
  //   // Remove any other auth-related cookies or local storage items
  // }

  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/sales/:salesID" element={<SalesDetail />} />
            <Route path="/salesperson" element={<SalesPerson />} />
            <Route
              path="/salesperson/:regID"
              element={<SalesPersonSalesInfo />}
            />

            <Route element={<AdminRoute />}>
              <Route path="/sales-app" element={<SalesApp />} />
              <Route
                path="/sales-app/user-attendence/:id?"
                element={<SalesAttendance />}
              />
              <Route path="/sales-app/sales/:id?" element={<AddSale />} />
              <Route
                path="/sales-app/user/:id?"
                element={<UserProfileForm />}
              />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
