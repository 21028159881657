import React, { useEffect, useState } from "react";
import "./AddSale.css"; // Make sure to create this CSS file for styling
import Button from "../Button/Button";
import apiClient from "../../utils/axiosConfig";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddSale() {
  const navigate = useNavigate();
  const searchParam = useSearchParams();
  const id = searchParam[0].get("id");
  // console.log(id);

  // Initial state for form data
  const [formData, setFormData] = useState({
    payment_id: "",
    order_id: "",
    amount: "",
    date_and_time: "",
    payment_method: "",
    customer_email: "",
    customer_phone: "",
    customer_name: "",
    batch: "",
    occupation: "",
    registration_code: "",
    internship: "",
  });

  // Initial state for additional data
  const [aditionalData, setaditionalData] = useState({
    ...formData,
    recording_file: null,
    invoice_sent: false,
    approval_status: "pending",
  });
  const [audioUrl, setaudioUrl] = useState("");

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setaditionalData({ ...aditionalData, recording_file: file });
  };

  // console.log(aditionalData.recording_file);

  const getAudio = async () => {
    try {
      const response = await apiClient.get(`/api/audio-file/${id}`);
      if (response.data) {
        setaudioUrl(response.data.audioFileUrl);
      } else {
        setaudioUrl("");
      }
      // console.log(response.data.fileUrl);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", aditionalData.recording_file);

      const response = await apiClient.put(`/api/upload/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.fileUrl) {
        toast.success("Recording uploaded successfully!");
        toast.success("Now You Can Click Save and Edit Sale!");

        // console.log(response.data.fileUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch sale data based on id
  const fetchSaleData = async (id) => {
    try {
      const response = await apiClient.get(`/api/sale/${id}`);
      // console.log(response?.data);
      const formattedDate = new Date(response.data.date_and_time)
        .toISOString()
        .slice(0, 16);
      if (response?.data) {
        // console.log(aditionalData);
        setaditionalData({
          ...aditionalData,
          invoice_sent: response.data.invoice_sent,
          approval_status: response.data.approval_status,
        });
        setFormData({
          payment_id: response.data.payment_id,
          order_id: response.data.order_id,
          amount: response.data.amount,
          date_and_time: formattedDate,
          payment_method: response.data.payment_method,
          customer_email: response.data.customer_email,
          customer_phone: response.data.customer_phone,
          customer_name: response.data.customer_name,
          batch: response.data.batch,
          occupation: response.data.occupation,
          registration_code: response.data.registration_code,
          internship: response.data.internship,
        });
      }
      if (response.data.recording_file !== null) {
        getAudio();
      }
      // console.log(aditionalData);
    } catch (error) {
      console.log("Error fetching sale data: ", error);
    }
  };

  // Fetch sale data if id is present
  useEffect(() => {
    if (id) {
      fetchSaleData(id);
      // console.log(id);
    }
    // eslint-disable-next-line
  }, [id]);

  // Handle input change for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setaditionalData({ ...aditionalData, [name]: value });
  };

  // Handle form submission for updating or adding sale
  const handleSubmit = async (e) => {
    // console.log(formData);
    e.preventDefault();
    // console.log(formData, aditionalData);
    try {
      if (id) {
        const response = await apiClient.put(`/api/sale/update/${id}`, {
          ...aditionalData,
        });

        if (response.data) {
          // console.log(response.data.message);
          toast.success(response.data.message);
        }
        navigate(`/sales-app`);
        // window.location.reload();
        fetchSaleData(id);
      } else {
        const response = await apiClient.post("/api/sale/newsale", {
          ...formData,
        });

        if (response.data) {
          // console.log(response.data.message);
          toast.success("Sale added successfully");
        }
        // Reset the form
        setFormData({
          payment_id: "",
          order_id: "",
          amount: "",
          date_and_time: "",
          payment_method: "",
          customer_email: "",
          customer_phone: "",
          customer_name: "",
          batch: "",
          occupation: "",
          registration_code: "",
          internship: "",
          recordingFile: null,
          invoice_sent: false,
          approval_status: "pending",
        });

        navigate(`/sales-app`);
      }
    } catch (error) {
      console.log("Error from AddSale Component: ", error);
    }
  };

  // Handle form submission for saving and adding another sale

  return (
    <>
      <Navbar />
      <div className="add-sale-container">
        <div className="add-sale-header">
          <Button text={"<- Back"} onClick={() => navigate(`/sales-app`)} />
        </div>

        <h2 className="add-sale-heading">
          {id ? "Edit Student Enrollement" : "Add Student Enrollement"}
        </h2>

        <form onSubmit={handleSubmit} className="add-sale-form">
          <div className="form-group">
            {/* Form fields for sale data */}
            <div className="form-item">
              <label htmlFor="payment_id">Payment ID</label>
              <input
                type="text"
                id="payment_id"
                name="payment_id"
                defaultValue={formData.payment_id}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="order_id">Order ID</label>
              <input
                type="text"
                id="order_id"
                name="order_id"
                defaultValue={formData.order_id}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="amount">Amount</label>
              <input
                type="text"
                id="amount"
                name="amount"
                defaultValue={formData.amount}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="date_and_time">Date-Time</label>
              <input
                type="datetime-local"
                id="date_and_time"
                name="date_and_time"
                defaultValue={formData.date_and_time}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="payment_method">Payment Method</label>
              <input
                type="text"
                id="payment_method"
                name="payment_method"
                defaultValue={formData.payment_method}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="customer_email">Customer Email</label>
              <input
                type="email"
                id="customer_email"
                name="customer_email"
                defaultValue={formData.customer_email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="customer_phone">Customer Phone</label>
              <input
                type="tel"
                id="customer_phone"
                name="customer_phone"
                defaultValue={formData.customer_phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="customer_name">Customer Name</label>
              <input
                type="text"
                id="customer_name"
                name="customer_name"
                defaultValue={formData.customer_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="batch">Batch</label>
              <input
                type="text"
                id="batch"
                name="batch"
                defaultValue={formData.batch}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="occupation">Occupation</label>
              <input
                type="text"
                id="occupation"
                name="occupation"
                defaultValue={formData.occupation}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="registration_code">Registration Code</label>
              <input
                type="text"
                id="registration_code"
                name="registration_code"
                defaultValue={formData.registration_code}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="internship">Internship</label>
              <input
                type="text"
                id="internship"
                name="internship"
                defaultValue={formData.internship}
                onChange={handleInputChange}
              />
            </div>

            {/* Conditional fields for editing sale */}
            {id && (
              <div>
                <div className="form-item">
                  <label htmlFor="recordingFile">Recording File</label>
                  <input
                    type="file"
                    accept="audio/*"
                    id="recordingFile"
                    name="recordingFile"
                    onChange={handleFileChange}
                  />
                  <div style={{ marginTop: "10px" }}>
                    <Button text={"Upload"} onClick={handleFileUpload} />
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    {audioUrl ? <audio src={`${audioUrl}`} controls /> : null}
                  </div>
                </div>
                <div className="form-item">
                  <label htmlFor="invoiceSent">Invoice Sent</label>
                  <select
                    name="invoiceSent"
                    id="invoiceSent"
                    value={aditionalData.invoice_sent}
                    onChange={(e) =>
                      setaditionalData({
                        ...aditionalData,
                        invoice_sent: e.target.value,
                      })
                    }
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="form-item">
                  <label htmlFor="approvalStatus">Approval Status</label>
                  <select
                    name="approvalStatus"
                    id="approvalStatus"
                    value={aditionalData.approval_status}
                    onChange={(e) =>
                      setaditionalData({
                        ...aditionalData,
                        approval_status: e.target.value,
                      })
                    }
                  >
                    <option value="approved">Approved</option>
                    <option value="pending">Pending</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
            )}
          </div>

          <div className="button-container">
            <div>
              {/* Conditional rendering of buttons */}
              {!id ? (
                <Button
                  text="Save and Add New"
                  type="submit"
                  className="submit-button"
                  size={"medium"}
                />
              ) : (
                <Button text="Edit and Save" type="submit" size={"medium"} />
              )}
            </div>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
}

export default AddSale;
