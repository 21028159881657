import React, { useState } from "react";
import "./login.css"; // Import the CSS file
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import apiClient from "../../utils/axiosConfig";
import { userState } from "../../Redux/userSlice";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import Cookies from "js-cookie";

const Login = () => {
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    // Clear error message for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted successfully");

      const { username, password } = values;
      setLoading(true);
      try {
        const response = await apiClient.post("/api/auth/login", {
          username,
          password,
        });

        console.log(response.data);

        const userRole = response?.data?.role;
        dispatch(userState(userRole));
        // Cookies.set("role", userRole);
        // Cookies.set({ role: userRole, isLogin: true });

        Cookies.set("role", userRole);
        Cookies.set("isLogin", true);

        console.log(Cookies.get());
        setMessage("You are logged in successfully!");
        navigate("/");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setMessage("Invalid credentials, please try again.");
        } else if (error.response && error.response.status === 500) {
          setMessage("Internal server error, please try again later.");
        } else {
          setMessage("An error occurred, please try again later.");
        }
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="section is-fullheight">
      <div className="container">
        <div className="column is-6 is-offset-3">
          <div className="box">
            <h1>Login</h1>
            {message && (
              <p
                className="message"
                style={{ color: "red", textAlign: "left", margin: "10px 0" }}
                aria-live="polite"
              >
                {message}
              </p>
            )}
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <div className="field">
                <label className="label" htmlFor="username">
                  Username
                </label>
                <div className="Username-input">
                  <input
                    id="username"
                    autoComplete="off"
                    className={`input ${errors.username ? "is-danger" : ""}`}
                    type="text"
                    name="username"
                    onChange={handleChange}
                    required
                    placeholder="Enter your Username"
                    aria-invalid={errors.username ? "true" : "false"}
                  />

                  {errors.username && (
                    <p className="help is-danger">{errors.username}</p>
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor="password">
                  Password
                </label>
                <div className="Password-input">
                  <input
                    id="password"
                    className={`input ${errors.password ? "is-danger" : ""}`}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    required
                    placeholder="Enter your password"
                    aria-invalid={errors.password ? "true" : "false"}
                  />
                  <div className="EyeIcons">
                    {showPassword ? (
                      <FaEyeSlash
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <FaEye onClick={() => setShowPassword(!showPassword)} />
                    )}
                  </div>
                  {errors.password && (
                    <p className="help is-danger">{errors.password}</p>
                  )}
                </div>
              </div>
              <Button
                text={loading ? "Logging in..." : "Login"}
                size="medium"
                type={"submit"}
                disabled={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
