import React from "react";

const GuardSvg = ({ fillColor, width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "18"}
      fill={fillColor || "#252C58"}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2575 1.24675L3.84083 4.09758C3.18083 4.39091 2.75 5.05092 2.75 5.77508V10.0834C2.75 15.1709 6.27 19.9284 11 21.0834C15.73 19.9284 19.25 15.1709 19.25 10.0834V5.77508C19.25 5.05092 18.8192 4.39091 18.1592 4.09758L11.7425 1.24675C11.275 1.03591 10.725 1.03591 10.2575 1.24675ZM11 10.9909H17.4167C16.9308 14.7676 14.41 18.1318 11 19.1859V11.0001H4.58333V5.77508L11 2.92425V10.9909Z"
        fill={fillColor || "#252C58"}
      />
    </svg>
  );
};

export default GuardSvg;
