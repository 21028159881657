import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./Table.css";
import apiClient from "../../utils/axiosConfig";
import { userSalesInfo } from "../../Redux/userSlice";
import ClipLoader from "react-spinners/ClipLoader";

const Table = () => {
  const selector = useSelector((state) => state.user.salesInfo);
  const varient = useSelector((state) => state.user.role);
  const dispatch = useDispatch();

  const getInitialDateRange = () => {
    const storedDates = localStorage.getItem("selectedDate");
    if (storedDates) {
      const parsedDates = JSON.parse(storedDates);
      return {
        startDate: new Date(parsedDates.startDate),
        endDate: new Date(parsedDates.endDate),
        key: "selection",
      };
    }
    return {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
  };

  const [selectedDate, setSelectedDate] = useState(getInitialDateRange);

  const handleSelect = (ranges) => {
    const newSelection = ranges.selection;
    setSelectedDate(newSelection);
    localStorage.setItem(
      "selectedDate",
      JSON.stringify({
        startDate: newSelection.startDate.toISOString(),
        endDate: newSelection.endDate.toISOString(),
      })
    );
  };

  const getSalesData = async () => {
    try {
      const response = await apiClient.get("/api/dashboard");
      if (response.data) {
        const sortedData = response.data.sales
          .slice()
          .sort(
            (a, b) => new Date(b.date_and_time) - new Date(a.date_and_time)
          );
        dispatch(userSalesInfo(sortedData));
      }
    } catch (error) {
      console.error(
        "Error fetching sales data:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    if (!selector.length) {
      getSalesData();
    }
  }, [selector.length]);

  const groupedSales = groupByDate(selector);

  const getSalesCount = (date) =>
    groupedSales[date] ? groupedSales[date].length : 0;
  const getTotalSalesAmount = (date) =>
    groupedSales[date]
      ? groupedSales[date].reduce(
          (acc, sale) => Number(acc) + Number(sale.amount),
          0
        )
      : 0;

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedHours = hours % 12 || 12;
    const ampm = hours >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  const startDate = new Date(selectedDate.startDate).setHours(0, 0, 0, 0);
  const endDate = new Date(selectedDate.endDate).setHours(23, 59, 59, 999);

  console.log("groupedSales", groupedSales);

  const filteredSales = Object.entries(groupedSales).reduce(
    (acc, [date, sales]) => {
      const salesDate = new Date(date).getTime();
      if (salesDate >= startDate && salesDate <= endDate) {
        acc[date] = sales;
      }
      return acc;
    },
    {}
  );
  console.log(filteredSales);

  // useEffect(() => {
  //   console.log([selectedDate, { ...selectedDate, key: "selection" }]);
  // }, [selectedDate]);

  if (!selector.length) {
    return (
      <div className="loader-container">
        <ClipLoader
          color={"#4b74ff"}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  // console.log([selectedDate, { ...selectedDate, key: "selection" }]);

  return (
    <div className="sales-container">
      <div className="sales-date-picker">
        <DateRangePicker
          ranges={[selectedDate]}
          onChange={handleSelect}
          className="responsive-date-range-picker"
        />
      </div>

      {!Object.keys(filteredSales).length && (
        <div className="sales-container">
          <h1>No Sales Found within the selected date range</h1>
        </div>
      )}

      {Object.keys(filteredSales).map((date) => (
        <div key={date} className="sales-inner-container">
          <div className="salestable-header">
            <h3>Student Enrollment on {date}</h3>
            <h4>Today's Count: {getSalesCount(date)}</h4>
            <h4>Total Amount: {getTotalSalesAmount(date)}</h4>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr className="table-header">
                  <th>#</th>
                  <th>Order ID</th>
                  <th>Amount</th>
                  <th>Time</th>
                  <th>Phone Number</th>
                  <th>Customer Name</th>
                  <th>Customer Email</th>
                  {varient === "salesperson" && <th>Payment Method</th>}
                  <th>Batch</th>
                  <th>Registration Code</th>
                  <th>Internship</th>
                  <th>Approval Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredSales[date]?.map((sale, i) => (
                  <tr key={sale.id} className="table-row">
                    <td>{i + 1}</td>
                    <td className="emp">
                      {varient === "salesperson" ? (
                        sale.order_id
                      ) : (
                        <Link to={`/sales/${sale.id}`}>{sale.order_id}</Link>
                      )}
                    </td>
                    <td className="opacity-low">{sale.amount}</td>
                    <td className="opacity-low">
                      {formatDate(sale.date_and_time)}
                    </td>
                    <td className="opacity-low">{sale.customer_phone}</td>
                    <td className="opacity-low">{sale.customer_name}</td>
                    <td className="opacity-low">{sale.customer_email}</td>
                    {varient === "salesperson" && (
                      <td className="opacity-low">{sale.payment_method}</td>
                    )}
                    <td className="opacity-low">{sale.batch}</td>
                    <td className="opacity-low">{sale.registration_code}</td>
                    <td className="opacity-low">{sale.internship}</td>
                    <td className={`opacity-low ${sale?.approval_status}`}>
                      {sale.approval_status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

const groupByDate = (sales) => {
  return sales.reduce((acc, sale) => {
    const date = new Date(sale.date_and_time).toDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(sale);
    return acc;
  }, {});
};

export default Table;
