import React from "react";
import SalesLead from "./SalesLead";
import Operational from "./Operational";
import "./DashboardComponents.css";
import Attendance from "../Attendance/Attendance";
import { OperationalData } from "../../utils/data";

const SuperAdmin = () => {
  return (
    <>
      <Operational />
      <SalesLead />
      <div>{/* <Attendance data={OperationalData} /> */}</div>
    </>
  );
};

export default SuperAdmin;
