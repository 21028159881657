import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../utils/axiosConfig";
import Cookies from "js-cookie";
import "./navbar.css";
import Logo1 from "../../assets/Logo1.gif";
import Logo2 from "../../assets/Logo2.png";
import UnifiedLogo from "../../assets/brandLogo.png";
import DashBoardIcon from "../../assets/dashbord-icon.png";
import UserIcon from "../../assets/usericon.png";
import Hamburger from "../../assets/hamburger.png";
import { toast } from "react-toastify";

const Navbar = ({ variant }) => {
  const navigate = useNavigate();
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selector = useSelector((state) => state.user.role);
  const message = useSelector((state) => state.user.welcomeMessage);

  const logout = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.get("/api/auth/logout");
      if (response.data) {
        Cookies.remove("role");
        Cookies.remove("isLogin");
        // console.log(Cookies.get());
        navigate("/login");
        window.location.reload();
      } else {
        console.error("Logout failed");
        toast.error("Logout failed");
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleHamburgerMenu = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  const ProfileMenu = () => (
    <ul className="navbar-admin">
      <li>
        <Link to={"/"}>Home</Link>
      </li>
      {selector === "superadmin" && (
        <li>
          <Link to={"/sales-app"}>Admin Panel</Link>
        </li>
      )}
      {selector === "superadmin" || selector === "sales_lead" ? (
        <li>
          <Link to={"/salesperson"}>Sales Persons</Link>
        </li>
      ) : null}

      <li>
        <button onClick={logout} disabled={isLoading}>
          Logout
        </button>
      </li>
    </ul>
  );

  const HamburgerMenu = () => (
    <div>
      <ul className="hamburger-menu navbar-admin">
        <li className="hamburger-message">
          {!message ? "Welcome to the dashboard" : message}
        </li>

        <li className="hamburger-admin">
          <Link to={"/"}>Home</Link>
        </li>

        {selector === "superadmin" && (
          <li className="hamburger-admin">
            <Link to={"/sales-app"}>Admin Panel</Link>
          </li>
        )}
        {selector === "superadmin" || selector === "sales_lead" ? (
          <li>
            <Link to={"/salesperson"}>Sales Persons</Link>
          </li>
        ) : null}

        <li>
          <button onClick={logout} disabled={isLoading}>
            Logout
          </button>
        </li>
      </ul>
    </div>
  );

  return (
    <nav className="navbar">
      <div className="navbar-logos">
        <figure className="navbar-logo-image">
          {/* <img src={UnifiedLogo} alt="Unified Logo" width={25} height={25} /> */}

          <Link to={"/"}>
            <div className="navbar-logo-gif">
              <img src={Logo1} alt="logo gif" />
              <img
                src={Logo2}
                alt="logo img"
                className="navbar-logo-img-text"
              />
            </div>
          </Link>
          {/* <figcaption className="navbar-logo-caption">
            {variant === "all" ? "Achieve" : "Achieve Administration"}
          </figcaption> */}
        </figure>
        {variant === "all" && (
          <figure className="navbar-logo-image hide">
            <figcaption className="navbar-logo-caption">Dashboard</figcaption>
            <img
              src={DashBoardIcon}
              alt="Dashboard Icon"
              width={50}
              height={50}
            />
          </figure>
        )}
      </div>

      <div className="navbar-actions">
        <p>{!message ? "Welcome to the dashboard" : message}</p>
        <span className="navbar-separator"></span>
        <div className="navbar-profile">
          <div onClick={() => setIsPopUp(!isPopUp)}>
            <img
              src={UserIcon}
              alt="Profile Icon"
              className="navbar-profile-image"
            />
          </div>
          {isPopUp && <ProfileMenu />}
        </div>
      </div>

      <div className="navbar-hamburger">
        <figure onClick={toggleHamburgerMenu}>
          <img src={Hamburger} alt="Hamburger Menu" width={25} height={25} />
        </figure>
        {isHamburgerOpen && <HamburgerMenu />}
      </div>
    </nav>
  );
};

export default Navbar;
