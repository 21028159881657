import React from "react";

const Bell = ({ fillColor, width, height, stroke }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "24"}
      viewBox="0 0 20 24"
      fill={fillColor || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9C16 7.4087 15.3679 5.88258 14.2426 4.75736C13.1174 3.63214 11.5913 3 10 3C8.4087 3 6.88258 3.63214 5.75736 4.75736C4.63214 5.88258 4 7.4087 4 9C4 16 1 18 1 18H19C19 18 16 16 16 9Z"
        stroke={stroke || "#252C58"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7295 22C11.5537 22.3031 11.3014 22.5547 10.9978 22.7295C10.6941 22.9044 10.3499 22.9965 9.99953 22.9965C9.64915 22.9965 9.30492 22.9044 9.0013 22.7295C8.69769 22.5547 8.44534 22.3031 8.26953 22"
        stroke={stroke || "#252C58"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* <circle cx="14.5" cy="3.5" r="3.5" fill="#3A69FF" /> */}
    </svg>
  );
};

export default Bell;
