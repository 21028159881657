import React from "react";
import "./SalesAdminstrationTable.css";
import CrossIcon from "../../../assets/Cross-markPNG.png";
import CheckIcon from "../../../assets/check-mark.png";
import { useNavigate } from "react-router-dom";

const UsersTable = ({
  setAddBtn,
  addBtn,
  userData,
  currentPage,
  itemsPerPage,
  handleDeleteUser,
}) => {
  const navigate = useNavigate();

  const handleEditUser = (id) => {
    const searchParams = new URLSearchParams();
    searchParams.set("userid", id);
    navigate(`/sales-app/user?${searchParams.toString()}`);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // const handleDeleteUser = async (id) => {
  //   try {
  //     const response = await apiClient.delete(`/api/user/delete/${id}`);
  //     if (response.data) {
  //       toast("User deleted successfully");
  //       // window.location.reload();
  //       dispatch(activeSaleSideNav("users"));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const currentSalesData = userData.slice(startIndex, endIndex);

  return (
    <div className="sales-table-container">
      {userData && userData.length > 0 ? (
        <table className="sales-table">
          <thead className="sales-table-header">
            <tr>
              <th>Username</th>
              <th>Email Address</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Staff Status</th>
              <th>Active Status</th>

              <th>Role</th>
              <th>Reporting Manager</th>
              <th>Registration Code</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody className="sales-table-body">
            {currentSalesData?.map((user) => (
              <tr key={user.id} className="sales-table-row">
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>
                  {user.is_staff === true ? (
                    <img src={CheckIcon} alt="" width={20} height={20} />
                  ) : (
                    <img src={CrossIcon} alt="" width={20} height={20} />
                  )}
                </td>
                <td>
                  {user.is_active === true ? (
                    <img src={CheckIcon} alt="" width={20} height={20} />
                  ) : (
                    <img src={CrossIcon} alt="" width={20} height={20} />
                  )}
                </td>

                <td>{user.role}</td>
                <td>{user.reporting_manager ? user.reporting_manager : "-"}</td>
                <td>{user.registration_code ? user.registration_code : "-"}</td>
                <td>
                  <button
                    className="edit-btn"
                    onClick={() => handleEditUser(user.id)}
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="delete-btn"
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-users-found">
          <p>No users found</p>
        </div>
      )}
    </div>
  );
};

export default UsersTable;
