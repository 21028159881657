import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import Button from "../Button/Button";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const MultiaxisChart = ({ allSalesData }) => {
  // State to manage active time frame and chart data
  const [activeTimeFrame, setActiveTimeFrame] = useState("hour");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  // Chart options configuration
  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Total Sales Over Time",
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: `${activeTimeFrame}`,
        },
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: "Time", // X-axis title
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Sales", // Y-axis title
        },
      },
    },
  };

  // Function to update chart data for daily sales
  const dailyChart = (data) => {
    // Create labels with date and time
    const labels = data?.map((sale) =>
      new Date(sale.date_and_time).toISOString()
    );
    const amounts = data.map((sale) => parseFloat(sale.amount));

    // Update chart data
    setChartData({
      labels,
      datasets: [
        {
          label: "Total Sales",
          data: amounts,
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    });
  };

  // Function to show today's sales
  const showTodaySales = () => {
    const today = new Date().toISOString().split("T")[0];
    const todaySales = allSalesData.filter(
      (sale) => sale.date_and_time.split("T")[0] === today
    );
    setActiveTimeFrame("hour");
    dailyChart(todaySales);
  };

  // Function to update chart data for weekly sales
  const showWeeklySales = () => {
    const today = new Date();
    const weekAgo = new Date(today);
    weekAgo.setDate(today.getDate() - 7);

    const weeklySales = allSalesData.filter((sale) => {
      const saleDate = new Date(sale.date_and_time);
      return saleDate >= weekAgo && saleDate <= today;
    });

    setActiveTimeFrame("day");
    updateChart(weeklySales);
  };

  // Function to update chart data for monthly sales
  const showMonthlySales = () => {
    const today = new Date();
    const monthAgo = new Date(today);
    monthAgo.setMonth(today.getMonth() - 1);

    const monthlySales = allSalesData.filter((sale) => {
      const saleDate = new Date(sale.date_and_time);
      return saleDate >= monthAgo && saleDate <= today;
    });

    setActiveTimeFrame("day");
    updateChart(monthlySales);
  };

  // Function to update chart data based on provided sales data
  const updateChart = (data) => {
    // Group data by date and calculate total amounts
    const dailySales = data.reduce((acc, sale) => {
      const saleDate = new Date(sale.date_and_time).toLocaleDateString();
      if (!acc[saleDate]) {
        acc[saleDate] = 0;
      }
      acc[saleDate] += parseFloat(sale.amount);
      return acc;
    }, {});

    // Extract labels (dates) and amounts from grouped data
    const labels = Object.keys(dailySales); // Array of date strings
    const amounts = Object.values(dailySales); // Array of total sales amounts

    // Convert labels to ISO strings if needed
    const convertIso = labels.map((label) => new Date(label).toISOString());

    // Update chart data
    setChartData({
      labels: convertIso, // Use converted ISO strings as labels
      datasets: [
        {
          label: "Total Sales",
          data: amounts,
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    });
  };

  // Effect to initially load today's sales when allSalesData changes
  useEffect(() => {
    showTodaySales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSalesData]);

  // Render component
  return (
    <div className="multiaxis-chart">
      {/* Buttons to show sales for Today, Week, and Month */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            width: "300px",
          }}
        >
          <Button text="Today" size="small" onClick={showTodaySales} />
          <Button text="Week" size="small" onClick={showWeeklySales} />
          <Button text="Month" size="small" onClick={showMonthlySales} />
        </div>
      </div>
      {/* Line chart displaying total sales over time */}
      <Line data={chartData} options={options} />
    </div>
  );
};

export default MultiaxisChart;
