import React from "react";

const CalenderSvg = ({ fillColor, width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "21"}
      viewBox="0 0 24 21"
      fill={fillColor || "#252C58"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_159_203)">
        <path
          d="M20 2.625H19V0.875H17V2.625H7V0.875H5V2.625H4C2.9 2.625 2 3.4125 2 4.375V18.375C2 19.3375 2.9 20.125 4 20.125H20C21.1 20.125 22 19.3375 22 18.375V4.375C22 3.4125 21.1 2.625 20 2.625ZM20 18.375H4V8.75H20V18.375ZM20 7H4V4.375H20V7Z"
          fill={fillColor || "#252C58"}
        />
      </g>
      <defs>
        <clipPath id="clip0_159_203">
          <rect width="24" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalenderSvg;
