import React from "react";

const SingleLineGraphSvg = ({ fillColor, width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={fillColor || "#252C58"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_159_199)">
        <path
          d="M4.2007 17.78L9.5007 12.48L12.7507 15.73C13.1607 16.14 13.8207 16.12 14.2007 15.69L21.3707 7.62003C21.7207 7.23003 21.7007 6.63003 21.3307 6.25003C20.9307 5.85003 20.2607 5.86003 19.8807 6.29003L13.4907 13.47L10.2007 10.18C9.8107 9.79003 9.1807 9.79003 8.7907 10.18L2.7007 16.28C2.3107 16.67 2.3107 17.3 2.7007 17.69L2.7907 17.78C3.1807 18.17 3.8207 18.17 4.2007 17.78Z"
          fill={fillColor || "#252C58"}
        />
      </g>
      <defs>
        <clipPath id="clip0_159_199">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SingleLineGraphSvg;
