import React, { useState } from "react";
import SalesNav from "../../components/SalesNav/SalesNav";
import SalesSideNav from "../../components/SalesSideNav/SalesSideNav";
import "./SalesApp.css";
import AddSale from "../../components/AddSale/AddSale";
import SalesAttendance from "../../components/SalesAttendance/SalesAttendance";
import UserMainContent from "../../components/SalesApp/UserMainContent/UserMainContent";
import SalesDataAdminstration from "../../components/SalesApp/SalesDataAdminintration/SalesDataAdminstration";
import UserProfileForm from "../../components/SalesApp/UserMainContent/UserAdd/UserProfileForm";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar/Navbar";

const SalesApp = () => {
  const [addBtn, setAddBtn] = useState({
    sales: false,
    attendance: false,
    user: false,
  });
  const activePage = useSelector((state) => state.user.activeSaleSideNav);
  // const [activePage, setActivePage] = useState("sales");
  return (
    <div>
      <Navbar />
      <div className="sales-main-app">
        <div className="sales-side-nav">
          <SalesSideNav
            setAddBtn={setAddBtn}
            addBtn={addBtn}
            activePage={activePage}
          />
        </div>
        {activePage === "sales" ? (
          <div className="sales-main">
            {/* sales */}
            {addBtn.sales === true ? (
              <AddSale />
            ) : (
              <div>
                <SalesDataAdminstration setAddBtn={setAddBtn} addBtn={addBtn} />
              </div>
            )}
          </div>
        ) : activePage === "attendance" ? (
          <div className="sales-main">
            {/* attendance */}
            {addBtn.attendance === true ? (
              <p>
                <span>No Data</span>
              </p>
            ) : (
              <SalesAttendance />
            )}
          </div>
        ) : (
          <div className="sales-main">
            {/* user */}
            {addBtn.user ? (
              <UserProfileForm />
            ) : (
              <UserMainContent setAddBtn={setAddBtn} addBtn={addBtn} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesApp;
