import React from "react";
import "./Cards.css";

const Cards = ({ varient, card, index }) => {
  return (
    <div>
      {varient === "sales" ? (
        <div className="cards-container sales-card" key={index}>
          <p>{card?.cardsTitle}</p>
          <p>{card?.subTitle}</p>
          <div className="lineChart">{card?.icon}</div>
        </div>
      ) : (
        <div className="cards-container" key={index}>
          <div className="cards-top">
            <span className="cards-title">{card?.cardsTitle}</span>
            <div className="img-container">
              <img src={card?.imageUrl} alt={card?.subTitle} className="img" />
            </div>
          </div>
          <p className="cards-subtitle">{card?.subTitle}</p>
          <div className="cards-bottom">
            <p className="cards-icon">
              {card?.imageIcon ? (
                <img
                  src={card?.imageIcon}
                  alt={card?.subTitle}
                  className="img"
                />
              ) : (
                <span className="cards-icon-text">{card?.icon}</span>
              )}
            </p>
            <p className="cards-text">{card?.footerText}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cards;
