import React, { useEffect, useState } from "react";
import "./salesDetails.css";
import Button from "../../components/Button/Button";
import { useParams } from "react-router-dom";
import apiClient from "../../utils/axiosConfig";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
const SalesDetail = () => {
  const { salesID } = useParams(); // Use the correct parameter name
  const roleSelector = useSelector((state) => state.user.role);
  const [salesdata, setSalesData] = useState({});
  const [loading, setLoading] = useState(false); // Loading state
  const [approvalStatus, setApprovalStatus] = useState("");
  const [recordingFile, setRecordingFile] = useState(null);
  const [audioUrl, setaudioUrl] = useState("");
  const [changeEmail, setChangeEmail] = useState(
    salesdata?.customer_email || ""
  );
  const [changeDomain, setChangeDomain] = useState(salesdata?.internship || "");
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  // Fetching data
  const fetchSingleSaleData = async () => {
    setLoading(true); // Start loading
    try {
      let response;
      if (roleSelector === "superadmin") {
        response = await apiClient.get(`/api/sale/sale/${salesID}`);
      } else if (
        roleSelector === "operations" ||
        roleSelector === "sales_lead"
      ) {
        response = await apiClient.get(`/api/sale/${salesID}`);
      }
      if (response?.data) {
        setSalesData(response?.data);
        if (response?.data?.recording_file !== null) {
          getAudio(false);
        }
      }
    } catch (error) {
      console.log("Error from fetchSingleSaleData: ", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    if (salesID) {
      fetchSingleSaleData();
    }
  }, [salesID]);

  useEffect(() => {
    setChangeEmail(salesdata?.customer_email || "");
    setChangeDomain(salesdata?.internship || "");
  }, [salesdata]);

  const sendInvoice = async () => {
    try {
      setInvoiceLoading(true);
      const response = await apiClient.post(`/api/send-invoice/${salesID}`);
      if (response.data) {
        toast.success("Invoice sent successfully");
        fetchSingleSaleData();
      } else {
        toast.info("Invoice not sent");
      }
    } catch (error) {
      console.log("Error from sendInvoice: ", error);
    } finally {
      setInvoiceLoading(false);
    }
  };

  const downloadInvoice = async () => {
    try {
      const response = await apiClient.get(`/api/generate-invoice/${salesID}`);
      if (response?.data?.invoiceUrl) {
        window.open(response?.data?.invoiceUrl, "_blank");
        toast.success("Invoice generated successfully");
        fetchSingleSaleData();
      } else {
        toast.info("Invoice not generated");
      }
    } catch (error) {
      console.log("Error from downloadInvoice: ", error);
    }
  };

  const handleUpdate = async () => {
    try {
      let response;
      if (roleSelector === "operations") {
        response = await apiClient.put(`/api/approve/${salesID}`, {
          ...salesdata,
          approval_status: approvalStatus,
        });
      } else {
        response = await apiClient.put(`/api/sale/update/${salesID}`, {
          ...salesdata,
          approval_status: approvalStatus,
        });
      }

      if (response.data) {
        toast.success("Status updated successfully");
        fetchSingleSaleData();
      }
    } catch (error) {
      console.log("Error from handleUpdateStatus: ", error);
    }
  };

  const getAudio = async (showToast = true) => {
    try {
      const response = await apiClient.get(`/api/audio-file/${salesID}`);
      if (response.data && response.data.audioFileUrl) {
        setaudioUrl(response.data.audioFileUrl);
        if (showToast) {
          toast.success(response.data.message);
        }
      } else {
        setaudioUrl("");
      }
    } catch (error) {
      console.error("Error from getAudio: ", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setRecordingFile(file);
  };

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      if (!recordingFile) {
        toast.info("Please select a file");
        return;
      }

      formData.append("file", recordingFile);

      const response = await apiClient.put(`/api/upload/${salesID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data?.message) {
        toast.success("Recording uploaded successfully!");
        // Update the state immediately
        setSalesData((prevData) => ({
          ...prevData,
          recording_file: recordingFile.name,
        }));
        getAudio(false); // This will update the audioUrl
      }
    } catch (error) {
      toast.error("Error uploading recording");
      console.error("Error from handleFileUpload: ", error);
    }
  };

  const downloadRecording = async () => {
    try {
      const response = await apiClient.get(`/api/audio-file/${salesID}`);
      if (response?.data?.audioFileUrl) {
        window.open(response?.data?.audioFileUrl, "_blank");
        toast.success("Recording downloaded successfully");
        fetchSingleSaleData();
      } else {
        toast.info("Recording not downloaded");
      }
    } catch (error) {
      console.log("Error from downloadRecording: ", error);
    }
  };

  const deleteRecording = async () => {
    try {
      const response = await apiClient.delete(`/api/audio-file/${salesID}`);
      if (response?.data) {
        toast.success("Recording deleted successfully!");
        // Update the state immediately
        setSalesData((prevData) => ({ ...prevData, recording_file: null }));
        setaudioUrl("");
      } else {
        toast.info("Recording not deleted");
      }
    } catch (error) {
      toast.error("Error deleting recording");
      console.error("Error from deleteRecording: ", error);
    }
  };

  const handleEmailandDomainSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {};

    if (changeEmail !== salesdata?.customer_email) {
      updatedData.customer_email = changeEmail;
    }

    if (changeDomain !== salesdata?.internship) {
      updatedData.internship = changeDomain;
    }

    if (Object.keys(updatedData).length === 0) {
      toast.info("No changes detected");
      return;
    }

    try {
      const response = await apiClient.put(
        `/api/updatedetails/${salesID}`,
        updatedData
      );
      if (response.data) {
        toast.success("Details updated successfully");
        fetchSingleSaleData();
      }
    } catch (error) {
      toast.error("Error updating details");
      console.error("Error from handleEmailandDomainSubmit: ", error);
    }
  };

  return (
    <div>
      <Navbar />
      {loading ? (
        <div className="loader-container">
          <ClipLoader
            color={"#4b74ff"}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="salesDetail-container">
          <div style={{ width: "100px", marginBottom: "20px" }}>
            <Button text="Back" onClick={() => window.history.back()} />
          </div>
          <h1 style={{ paddingBottom: "20px" }}>Enrollement Detail</h1>
          <div className="salesDetail-infos">
            <div className="salesDetail-details">
              <div className="salesDetail-left">
                <p>
                  Order Id: <span>{salesdata?.order_id}</span>
                </p>
                <p>
                  Payment Id: <span>{salesdata?.payment_id}</span>
                </p>
                <p>
                  Invoice No:{" "}
                  <span>
                    {salesdata?.invoice_id === null
                      ? "N/A"
                      : salesdata?.invoice_id}
                  </span>
                </p>
              </div>
              <div className="salesDetail-right">
                <p>
                  Amount: <span>{salesdata?.amount}</span>
                </p>
              </div>
            </div>
            <div className="additional-details-container">
              <h2 style={{ textAlign: "left" }}>Additional Details:</h2>
              <div className="additional-details">
                <div className="additional-details-left">
                  <p>
                    Date and Time:{" "}
                    <span>
                      {new Date(salesdata?.date_and_time).toDateString()}
                    </span>
                  </p>
                  <p>
                    Batch Enrolled: <span>{salesdata?.batch}</span>
                  </p>
                  <p>
                    Customer Name: <span>{salesdata?.customer_name}</span>
                  </p>
                  <p>
                    Customer Email: <span>{salesdata?.customer_email}</span>
                  </p>
                  {roleSelector !== "salesperson" && (
                    <p className="SalesDetailsInput">
                      {/* email field */}
                      <label htmlFor="email"> Modify Email : </label>
                      <input
                        type="email"
                        id="email"
                        value={changeEmail || salesdata?.customer_email}
                        onChange={(e) => setChangeEmail(e.target.value)}
                      />
                    </p>
                  )}

                  <p>
                    Customer Phone: <span>{salesdata?.customer_phone}</span>
                  </p>
                </div>

                <div className="additional-details-right">
                  <p>
                    Occupation: <span>{salesdata?.occupation}</span>
                  </p>
                  <p>
                    Registration Code:{" "}
                    <span>{salesdata?.registration_code}</span>
                  </p>
                  <p>
                    Payment Method: <span>{salesdata?.payment_method}</span>
                  </p>
                  <p>
                    Internship Program: <span>{salesdata?.internship}</span>
                  </p>
                  {roleSelector !== "salesperson" && (
                    <p className="SalesDetailsInput">
                      {/* email field */}
                      <label htmlFor="domain">
                        {" "}
                        Modify Internship Program :{" "}
                      </label>
                      <input
                        type="text"
                        id="domain"
                        value={changeDomain || salesdata?.internship}
                        onChange={(e) => setChangeDomain(e.target.value)}
                      />
                    </p>
                  )}
                </div>
              </div>
              {roleSelector !== "salesperson" && (
                <div className="salesDetail-submit-section">
                  <button
                    onClick={handleEmailandDomainSubmit}
                    className="salesDetail-submit-btn"
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
            <div className="invoice-section">
              <h3 className="invoice-title">
                Invoice Sent:{" "}
                <span>{salesdata?.invoice_sent ? "Yes" : "No"}</span>
              </h3>
              {roleSelector === "operations" ||
              roleSelector === "superadmin" ? (
                <div>
                  <div className="invoice-button">
                    <Button text="Download Invoice" onClick={downloadInvoice} />
                  </div>
                  <div className="invoice-button">
                    <Button
                      text={invoiceLoading ? "Sending..." : "Send Invoice"}
                      onClick={sendInvoice}
                      disabled={invoiceLoading}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="approval-status">
              {roleSelector === "operations" ||
              roleSelector === "superadmin" ? (
                <div>
                  <h4 className="invoice-title">
                    Approval Status:{" "}
                    <span
                      className={`${salesdata?.approval_status} status approval-status-state`}
                    >
                      {salesdata?.approval_status}
                    </span>
                  </h4>
                  <div>
                    <div className="Approval_Status_container">
                      <div className="approval_status_inner">
                        <label
                          htmlFor="approval_status"
                          className="invoice-title"
                        >
                          {" "}
                          Change Approval Status:{" "}
                        </label>
                        <select
                          name="approval_status"
                          id="approval_status"
                          defaultValue={salesdata?.approval_status}
                          onChange={(e) => setApprovalStatus(e.target.value)}
                        >
                          <option value=""> Select </option>
                          {salesdata?.recording_file !== null && (
                            <option value="approved">Approved</option>
                          )}
                          <option value="pending">Pending</option>
                          <option value="rejected">Rejected</option>
                        </select>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            width: "200px",
                            margin: "10px auto",
                          }}
                        >
                          <Button
                            text={"Update status"}
                            onClick={handleUpdate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="recording-file-section">
                    <label htmlFor="recordingFile" className="file-label">
                      Recording File:
                    </label>
                    <div className="file-input-container">
                      {salesdata?.recording_file !== null && audioUrl ? (
                        <audio src={audioUrl} controls />
                      ) : (
                        <input
                          type="file"
                          accept="audio/*"
                          id="recordingFile"
                          name="recordingFile"
                          onChange={handleFileChange}
                          className="file-input"
                        />
                      )}

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div style={{ width: "200px", margin: "10px auto" }}>
                          {salesdata?.recording_file !== null && audioUrl ? (
                            <Button
                              text={"Delete Recording"}
                              onClick={deleteRecording}
                            />
                          ) : (
                            <Button
                              text={"Update Recording"}
                              onClick={handleFileUpload}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <h4 className="invoice-title">
                    Approval Status: <span>{salesdata?.approval_status}</span>
                  </h4>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <h5 className="invoice-subtitle">
                  {salesdata?.recording_file === null
                    ? "Recording is not available yet"
                    : "Recording is available"}
                </h5>

                {roleSelector === "sales_lead" && audioUrl.length > 0 ? (
                  <audio src={`${audioUrl}`} controls />
                ) : null}
                {/* {roleSelector === "operations" ||
                (roleSelector === "superadmin" &&
                  salesdata?.recording_file === null) ? null : (
                  <div style={{ width: "200px" }}>
                    <Button
                      text="Download Recording"
                      onClick={downloadRecording}
                    />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SalesDetail;
