import axios from "axios";
import { useSelector } from "react-redux";

const configureStore = {
  development: {
    apiBaseUrl:
      "https://achieveportalpernbackend-unifiedmentors-projects.vercel.app/",
  },
};

//

const env = "development";

console.log(`Configuring Axios with ${configureStore[env].apiBaseUrl}`);

const apiClient = axios.create({
  baseURL: configureStore[env].apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
