import React from "react";

const LineSvg = ({ stroke, width, height }) => {
  return (
    <svg
      width={width || "64"}
      height={height || "64"}
      viewBox="0 0 64 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 51C5.41515 51 5.228 24.6308 9.64315 24.6308C14.0583 24.6308 14.8351 30.4525 19.2503 30.4525C23.6654 30.4525 24.3936 3.37319 28.8088 3.37319C33.2239 3.37319 32.8797 11.793 37.2949 11.793C41.71 11.793 40.8706 16.7143 45.2857 16.7143C49.7009 16.7143 51.506 30.4525 55.9212 30.4525C60.3363 30.4525 58.5848 3 63 3"
        stroke={stroke || "#252C58"}
        strokeWidth="5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LineSvg;
